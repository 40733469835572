<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <!-- <q-input outlined v-model="search" dense type="text" label="Pesquisar">
              <template v-slot:prepend>
                <q-icon name="eva-search-outline" />
              </template>
            </q-input> -->
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> {{ $t('filter_report') }}</q-tooltip>
      </q-btn>
    </q-toolbar>
    <!-- <q-intersection transition="slide-down" v-show="filter"> -->
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }}</q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-input outlined v-model="params.orderId" type="text" :label="$t('request_number')" />
        </div>
        <div class="col-3">
          <q-input outlined v-model="params.actionId" type="text" :label="$t('conversion_id')" />
        </div>
        <div class="col-3">
          <q-select
            outlined
            v-model="params.commissionType"
            type="text"
            :options="[
              { label: 'CPA', value: 'cpa' },
              { label: 'CPL', value: 'cpl' },
              { label: 'Bônus', value: 'bonus' }
            ]"
            option-label="label"
            option-value="value"
            :label="$t('type')"
          />
        </div>
        <div class="col-4">
          <select-request
            :v-model="params.advertiserId"
            outlined
            endpoint="redirect?app=ADVERTISER&path=/api/get/advertiser"
            option-label="trademark"
            option-value="id"
            :label="$t('fantasy_name')"
            clearable
            @update="(value) => (params.advertiserId = value === null ? 0 : value.id)"
            :formatLabelAdvertiser="true"
          />
        </div>
        <div class="col-3">
          <q-select
            outlined
            v-model="params.segmentation"
            :options="optionsSegmentation"
            :option-label="mapDescription"
            option-value="id"
            label="Segmentação"
          >
            <template v-if="params.segmentation" v-slot:append>
              <q-icon name="mdi-close" @click.stop="params.segmentation = null" class="cursor-pointer" />
            </template>
          </q-select>
        </div>
        <div class="col-5">
          <select-request
            :v-model="params.campaignId"
            outlined
            endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign"
            option-label="name"
            option-value="id"
            :label="$t('campaign')"
            clearable
            @update="(value) => (params.campaignId = value === null ? 0 : value.id)"
            :formatLabelCampaign="true"
          />
        </div>
        <div class="col-4">
          <date-input
            buttons
            ref="dateInput"
            outlined
            :label="$t('conversion_date')"
            :v-model="params.date"
            @update="(value) => (params.date = value)"
            clearable
            today-btn
            range
          />
        </div>

        <div class="col-4">
          <select-request
            :v-model="params.network_manager_id"
            outlined
            endpoint="/user/affiliate/managers/redes"
            option-label="name"
            option-value="user_id"
            :label="$t('affiliate_manager')"
            clearable
            @update="(value) => (params.network_manager_id = value === null ? 0 : value.user_id)"
          />
        </div>

        <div class="col-4">
          <div class="row q-col-gutter-sm">
            <div class="col">
              <q-select
                v-model="params.status"
                :options="[
                  {
                    label: $t('approved'),
                    value: 2
                  },
                  {
                    label: $t('pending'),
                    value: 1
                  },
                  {
                    label: $t('refused'),
                    value: 0
                  }
                ]"
                :label="$t('conversion_status')"
                outlined
                clearable
                clear-icon="mdi-close"
              />
            </div>
          </div>
        </div>
        <div class="col-4">
          <select-request
            :v-model="params.affiliateId"
            outlined
            endpoint="/redirect?app=affiliate&path=/api/get/affiliate/search"
            option-label="name"
            option-value="id"
            :label="$t('affiliate_id')"
            clearable
            @update="(value) => (params.affiliateId = value === null ? 0 : value.id)"
            :formatLabelAffiliate="true"
          />
        </div>
        <div class="col-4">
          <select-request
            :v-model="params.manager_id"
            :endpoint="'/user/managers-unblocked'"
            label="Gerente"
            outlined
            clearable
            option-label="name"
            option-value="user_id"
            @update="(val) => (params.manager_id = val === null ? 0 : val.user_id)"
          />
        </div>
        <div class="col-4">
          <select-request
            :v-model="params.siteId"
            outlined
            endpoint="/redirect?app=AFFILIATE&path=/api/v2/affiliate/site/list"
            option-label="name"
            option-value="id"
            :label="$t('channel_name')"
            clearable
            @update="(value) => (params.siteId = value === null ? null : value.id)"
            :formatLabelChannel="true"
          />
        </div>

        <div class="col-6">
          <q-checkbox
            v-model="params.test_campaigns"
            :false-value="true"
            :true-value="false"
            :label="$t('dont_show_test_campaign')"
          />
        </div>

        <div class="col-6 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterReports" />
        </div>
      </q-card-section>
    </q-card>
    <!-- </q-intersection> -->
  </div>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderConversionReports',
  components: { SelectRequest, DateInput },
  mixins: [ReportMixin],
  data() {
    return {
      filter: true,
      params: {
        orderId: null,
        actionId: null,
        advertiserId: null,
        campaignId: null,
        date: '',
        status: null,
        affiliateId: null,
        siteName: null,
        commissionType: null,
        manager_id: null,
        test_campaigns: false,
        segmentation: null,
        network_manager_id: null
      },

      optionsSegmentation: []
    };
  },

  mounted() {
    const yesterday = {
      to: this.$moment(),
      from: this.$moment().subtract(1, 'days')
    };
    this.$refs.dateInput.setValue(yesterday);

    this.getSegmentations();
  },

  methods: {
    async getSegmentations() {
      try {
        const { data } = await this.$http.get('redirect?app=advertiser&path=/api/get/campaign/segmentation');
        this.optionsSegmentation = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    mapDescription(item) {
      switch (item.id) {
        case 'AFILIO':
          return 'Campanhas Afilio';
        case 'BET':
          return 'Campanhas BET';
        case 'CJ':
            return 'Campanhas CJ';
      }
    },

    clearFilter() {
      (this.params = {
        orderId: null,
        actionId: null,
        advertiserId: 0,
        campaignId: 0,
        date: null,
        status: null,
        commissionType: null,
        test_campaigns: false,
        network_manager_id: 0,
        manager_id: 0,
        affiliateId: 0,
      }),
        // this.$refs.dateInput.clearValue();
      this.ActionSetFilterReports();
      this.getConversionReport();
    },

    async onFilterReports() {
      let filterSelect = [];

      if (this.params.orderId) filterSelect.push(`order_id=${this.params.orderId}`);
      if (this.params.actionId) filterSelect.push(`transaction_id=${this.params.actionId}`);
      if (this.params.advertiserId) filterSelect.push(`advertiser_id=${this.params.advertiserId}`);
      if (this.params.segmentation) filterSelect.push(`segmentation=${this.params.segmentation.id}`);
      if (this.params.campaignId) filterSelect.push(`campaign_id=${this.params.campaignId}`);
      if (this.params.affiliateId) filterSelect.push(`affiliate_id=${this.params.affiliateId}`);
      if (this.params.siteId) filterSelect.push(`site_id=${this.params.siteId}`);
      if (this.params.manager_id) filterSelect.push(`manager_id=${this.params.manager_id}`);
      if (this.params.commissionType) filterSelect.push(`comission_type=${this.params.commissionType.value}`);
      if (this.params.network_manager_id) filterSelect.push(`network_manager_id=${this.params.network_manager_id}`);

      if (this.params.date) {
        let [from, , to] = this.params.date.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`start_date=${from}`);
        filterSelect.push(`end_date=${to}`);
      }

      if (this.params.status) filterSelect.push(`validation_status=${this.params.status.value}`);

      this.params.test_campaigns ? filterSelect.push(`test_campaigns=1`) : filterSelect.push(`test_campaigns=0`);

      this.ActionSetFilterReports(filterSelect.join('%26'));
      await this.getConversionReport();

      if (this.reports.length > 0) {
        this.addTotalValues();
      }
    },

    addTotalValues() {
      this.reports.splice(10, 0, {
        campaign_name: 'TOTAL:',
        transaction_amount: this.parseMoney(this.reportsTotal[0].transaction_amount),
        full_commission_amount: this.parseMoney(this.reportsTotal[0].full_commission_amount),
        affiliate_commission_cost: this.parseMoney(this.reportsTotal[0].affiliate_commission_cost),
        afilio_commition_margin: this.parseMoney(this.reportsTotal[0].afilio_commition_margin),
        created_at: this.$moment().format('YYYY-MM-DD')
      });

      this.$nextTick(() => {
        const rows = document.querySelectorAll('.q-table tbody tr');
        rows.forEach((row) => {
          const columns = row.querySelectorAll('td');
          columns.forEach((column) => {
            if (column.innerText.trim() === 'TOTAL:') {
              row.classList.add('total-row');
            }
          });
        });
      });
    }
  }
};
</script>
