<template>
  <q-layout>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> {{ $t('conversion_report') }} </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el :label="$t('conversion_report')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />

    <q-page padding>
      <header-conversion-reports></header-conversion-reports>
      <list-conversion-reports></list-conversion-reports>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderConversionReports from '../../components/reports/HeaderConversionReports.vue';
import ListConversionReports from '../../components/reports/ListConversionReports.vue';
import ReportMixin from '../../mixins/ReportMixin';

export default {
  name: 'ConversionReports',
  components: { HeaderConversionReports, ListConversionReports },
  mixins: [ReportMixin]
};
</script>
